import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Creators } from 'store/ducks/athlete';
import { useTranslation } from 'react-i18next';
import { Card, Spinner, Menu, IconButton, Dialog } from 'components/template';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import styles from './Evaluations.module.scss';
import EvaluationsTable from 'components/EvaluationsTable/EvaluationsTable';
import IconButtonText from 'components/template/IconButtonText/IconButtonText';
import moment from 'moment';
import { FaTrashAlt } from 'react-icons/fa';

export default function Evaluations({ athleteId, setEvaluationCount }) {
  const history = useHistory();
  const location = useLocation();

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const evaluations = useSelector(state => state.athlete.evaluationsList);

  const initialFilters = {
    analyst: [],
    club: [],
    date: [],
    priority: [],
    mode: [],
    recommendation: [],
  };

  const [analysts, setAnalysts] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [dates, setDates] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [modes, setModes] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [checkedEvaluations, setCheckedEvaluations] = useState([]);

  const handleEvaluationsDelete = () => {
    setIsOpenDialog(false);
    dispatch(
      Creators.deleteEvaluationsLists(
        checkedEvaluations.map(evaluation => evaluation.id),
        athleteId            
      )
    );    
    setEvaluationCount(checkedEvaluations.length);
    setCheckedEvaluations([]);
  };

  useEffect(() => {
    dispatch(Creators.getEvaluationsList(athleteId));

    return () => {
      dispatch(Creators.resetEvaluationsList());
    };
  }, [dispatch, athleteId]);

  useEffect(() => {
    const uniqueNames = new Set();
    const uniqueClubs = new Set();
    const uniqueDates = new Set();
    const uniquePriorities = new Set();
    const uniqueModes = new Set();
    const uniqueRecommendations = new Set();

    evaluations.data.forEach(evaluation => {
      if (evaluation.author === undefined) {
        return;
      }
      uniqueNames.add(evaluation.author);
    });

    evaluations.data.forEach(evaluation => {
      if (evaluation.team.name === undefined) {
        return;
      }
      uniqueClubs.add(evaluation.team.name);
    });

    evaluations.data.forEach(evaluation => {
      if (evaluation.date === undefined) {
        return;
      }

      uniqueDates.add(moment(evaluation.date).format('DD/MM/YYYY'));
    });

    evaluations.data.forEach(evaluation => {
      if (evaluation.priority === undefined) {
        return;
      }
      uniquePriorities.add(evaluation.priority);
    });

    evaluations.data.forEach(evaluation => {
      if (evaluation.mode === undefined) {
        return;
      }
      uniqueModes.add(evaluation.mode);
    });

    evaluations.data.forEach(evaluation => {
      if (evaluation.recommendation === undefined) {
        return;
      }
      uniqueRecommendations.add(evaluation.recommendation);
    });

    setAnalysts(Array.from(uniqueNames));
    setClubs(Array.from(uniqueClubs));
    setDates(Array.from(uniqueDates));
    setPriorities(Array.from(uniquePriorities));
    setModes(Array.from(uniqueModes));
    setRecommendations(Array.from(uniqueRecommendations));
  }, [evaluations]);

  const filteredEvaluations = evaluations.data.filter(evaluation => {
    const analystFilter = filters['analyst'];
    const clubFilter = filters['club'];
    const dateFilter = filters['date'];
    const priorityFilter = filters['priority'];
    const modeFilter = filters['mode'];
    const recommendationFilter = filters['recommendation'];    

    if (
      (analystFilter.length === 0 || analystFilter.includes(evaluation.author)) &&
      (clubFilter.length === 0 || clubFilter.includes(evaluation.team.name)) &&
      (dateFilter.length === 0 || dateFilter.includes(moment(evaluation.date).format('DD/MM/YYYY'))) &&
      (priorityFilter.length === 0 || priorityFilter.includes(evaluation.priority)) &&
      (modeFilter.length === 0 || modeFilter.includes(evaluation.mode)) &&
      (recommendationFilter.length === 0 || recommendationFilter.includes(evaluation.recommendation))
    ) {
      return true;
    }

    return false;
  });

  let content = '';
  if (evaluations.loading) {
    content = (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  } else if (evaluations.error) {
    content = (
      <Card bordered softShadow className={styles.evaluations}>
        <span>{t(evaluations.error)}</span>;/
      </Card>
    );
  } else if (evaluations.data) {
    content = (
      <div className={styles.evaluations}>
        <div className={styles.header_container}>
          <span className={styles.header}>{t('field.re_ava1')}</span>
          <span className={styles.sub_header}>{t('field.re_ava2')}</span>
        </div>
        <div className={styles.filter_container}>
          <IconButton
            className={styles.deleteButton}
            onClick={() => {
              setIsOpenDialog(true);
            }}
            icon={<FaTrashAlt />}
            text={t('buttons.excluir')}
            disabled={checkedEvaluations.length === 0}
          />
          <Menu
            className={styles.menu}
            allowEmpty
            rounded
            title={t('charts.byAnalist')}
            placeholder={t('charts.byAnalist')}
            options={analysts.map(item => ({
              label: item,
              value: item,
            }))}
            value={filters['analyst']}
            setValue={val => {
              setFilters({ ...filters, analyst: [val] });
            }}
            onClear={() => {
              setFilters({ ...filters, analyst: [] });
            }}
          />          
          <Menu
            className={styles.menu}
            title={t('charts.filterByPeriod')}
            placeholder={t('charts.filterByPeriod')}
            rounded
            options={dates.map(item => ({
              label: item,
              value: item,
            }))}
            value={filters['date']}
            setValue={val => {
              setFilters({ ...filters, date: [val] });
            }}
            onClear={() => {
              setFilters({ ...filters, date: [] });
            }}
            allowEmpty
          />
          <Menu
            className={styles.menu}
            title={t('charts.filterByTargeting')}
            placeholder={t('charts.filterByTargeting')}
            rounded
            options={recommendations.map(item => ({
              label: t(`config.${item}`),
              value: item,
            }))}
            value={filters['recommendation']}
            setValue={val => {
              setFilters({ ...filters, recommendation: [val] });
            }}
            onClear={() => {
              setFilters({ ...filters, recommendation: [] });
            }}
            allowEmpty
          />
          <Menu
            className={styles.menu}
            title={t('filters.byPriority')}
            placeholder={t('filters.byPriority')}
            rounded
            options={priorities.map(item => ({
              label: t(`config.${item}`),
              value: item,
            }))}
            value={filters['priority']}
            setValue={val => {              
              setFilters({ ...filters, priority: [val] });
            }}
            onClear={() => {
              setFilters({ ...filters, priority: [] });
            }}
            allowEmpty
          />
          <Menu
            className={styles.menu}
            title={t('charts.byModel')}
            placeholder={t('charts.byModel')}
            rounded
            options={modes.map(item => ({
              label: t(`avaliationModel.${item}`),
              value: item,
            }))}
            value={filters['mode']}
            setValue={val => {              
              setFilters({ ...filters, mode: [val] });
            }}
            onClear={() => {
              setFilters({ ...filters, mode: [] });
            }}
            allowEmpty
          />
          <Menu
            className={styles.menu}
            allowEmpty
            rounded
            title={t('charts.byMomentClubShort')}
            placeholder={t('charts.byMomentClubShort')}
            options={clubs.map(item => ({
              label: item,
              value: item,
            }))}
            value={filters['club']}
            setValue={val => {
              setFilters({ ...filters, club: [val] });
            }}
            onClear={() => {
              setFilters({ ...filters, club: [] });
            }}
          />
          <IconButtonText
            onClick={() => history.push(`${location.pathname}/nova`)}
            color="lightPurple1"
            text={t('field.add_ava')}
            icon={<BsFillPlusCircleFill />}
          />
        </div>

        <EvaluationsTable
          evaluations={!filteredEvaluations || evaluations?.data?.length === 0 ? [] : filteredEvaluations}
          checkedEvaluations={checkedEvaluations}
          setCheckedEvaluations={setCheckedEvaluations}
        />
      </div>
    );
  }

  return <>
    {content}
    <Dialog
      isOpen={isOpenDialog}
      onClose={() => setIsOpenDialog(false)}
      mainTitle={t('organi.text8')}
      btnLeft={t('dialog_content.cancel')}
      btnRight={t('organi.text9')}
      btnLeftClick={() => setIsOpenDialog(false)}
      btnRightClick={() => handleEvaluationsDelete()}
    >
      {t('organi.text10')}
    </Dialog>
  </>;
}

Evaluations.propTypes = {
  athleteId: PropTypes.string.isRequired,
};
