export const TRANSLATIONS_EN = {
  pagina_inicial: {
    homepage_1: 'Last Transactions',
    homepage_2: 'System Information',
    homepage_3: 'Open windows',
    homepage_4: 'Last Viewed',
    homepage_5: 'See more',
    homepage_5_2: 'See more moves',
    homepage_6: 'Show all windows',
    homepage_7: 'Everything',
    homepage_8: 'IDB records',
    homepage_9: 'Activities',
    homepage_10: 'Information',
    homepage_11: 'Updated on',
    homepage_15: 'Windows transactions',
    homepage_16: 'My Feed',
    homepage_17: 'Your feed with everything that happened since your last access.',
    homepage_18: 'There are no open windows at the motrat.',
    homepage_19: 'See Less',
    homepage_20: 'Negotiations',
    open: 'Open',
    monitored: 'Monitored',
    selectToMonitor: 'Select Windows to monitor',
    youDontMonitor: 'You don\'t monitor transfer windows yet'
  },

  buttons: {
    excluir: 'Delete',
    cancel: 'Cancel',
    save: 'Save',
    edit: 'Edit',
  },

  nav_drawer: {
    meuPerfil: 'My Profile',
    organizacao: 'Organization',
    verPerfilMeuClube: 'View my club profile',
    verPerfilMinhaAgencia: 'View my agency profile',
    feed: 'My Feed',
    buscaAtletas: 'Search Athletes',
    clubes: 'Clubs',
    meuClube: 'My Club',
    competicoesBase: 'Competitions',
    analiseMercado: 'Market Analysis',
    mercadoTransferencias: 'Transfer Market',
    redeProfissionais: 'Network',
    agencias: 'Agencies',
    comprar: 'PURCHASE',
    dashboard: 'Dashboard',
  },

  athlete_card: {
    without_agency: 'Uninformed',
    idade: ' years',
    meses: 'months and',
    days: 'days',
    text1: 'Contracts at CBF',
    text2: 'BID date',
    text3: 'Start',
    text4: 'End',
    text5: 'State',
    text6: 'Value(M €)',
    text7: 'Total moved:',
    text8: 'Type of Trading: All',
    text9: 'Announcement Status: All',
    edit_pic: 'Edit Photo',
    nickname: 'Nickname',
    fullname: 'Full Name',
    nation: 'Nationality',
    birth: 'Birth',
    height: 'Height',
    weight: 'Weight',
    pos: 'Position',
    foot: 'Preferred foot',
    edit_athl: 'Edit Athlete',
    add_athl: 'Add Athlete',
    add_athl_msg1: 'The added athlete will only be visible to your organization!',
    add_athl_msg2: 'Rest assured to use it in Market Analysis.',
    filter1: 'Club: All',
    anotacoes: 'notes',
    buyValuation: 'Buy Valuation',
    zero_cost: 'Zero Cost',
    selectTeam: 'Select Team',
    error: {
      nationalities: 'Fill in at least one nationality!',
      name: 'The name entered must not contain invalid characters.',
      birthdate: 'The athlete must be at least 7 years old to be registered.',
      is_retired: 'This athlete is no longer active on Footlink.',
    },
  },

  athlete_list: {
    box1: 'Player',
    box2: 'Club',
    curret_club: 'Current club',
    box3: 'Age',
    box4: 'Position',
    box5: 'Nation.',
    box6: 'End of contract',
    exist: 'There is ',
    existplural: 'There are ',
    founded: 'We found ',
    tip: 'Generate Report',
    text1: ' athlete',
    text1plural: ' athletes',
    text2: ' athlete with the criteria of your search',
    text2plural: ' athletes with the criteria of your search',
    noAthletes: 'No athlete was found with the criteria of your search.',
    noAthletesReport: 'You must select at least one athlete to generate the report.',
    ageInComp: 'Age in competition', 
  },

  month: {
    // Janeiro,
    // Fevereiro,
    // Março,
    // Abril,
    // Maio,
    // Junho,
    // Julho: "",
    // Agosto: "",
    // Setembro: "",
    // Outubro: "",
    // Novembro: "",
    // Dezembro: "December",
  },

  contact_card: {
    type: 'Type',
    category: 'Category',
    role: 'Role',
    phone: 'Phone',
    error: 'Ocorreu um problema, favor revise seus dados',
  },

  table: {
    origin: 'Origin',
    destiny: 'Destiny',
    age_moment: 'Age at the moment',
    price: 'Value',
    text: 'See review',
    text2: 'See',
    data: 'Date',
    analist: 'Analyst',
    clube: 'Club at the moment',
    model: 'Evaluation model',
    general: 'General Evaluation',
    criadoEm: 'Created At',
    atualizadoEm: 'Updated At',
    atleta: 'Athlete',
    idade: 'Age',
    posicao: 'Position',
    nasc: 'Birth',
    contrato: 'Contract until',
  },

  dialog_content: {
    list: 'New List',
    warn: 'A list with the entered name already exists',
    create: 'Create List',
    cancel: 'Cancel',
    noCancel: 'No, cancel',
    include: 'Select where you want to add the athlete',
    save: 'Save',
    proceed: 'Proceed',
    yesProceed: 'Yes, proceed',
    new_create: 'Create new list',
    includeStep: 'Select the Stage where you want to add the athlete',
    selectStep: 'Select Stage',
    myLists: 'My Lists',
    orgLists: 'Lists of my Organization',
    myProjects: 'My Projects',
    removeFromProject: 'Remove Athlete from Project',    
    createSuccess: 'Successful creation: ',
    unexpectedError: 'Unexpected error when updating workflow: ',    
    editList: 'Edit List',
    the: 'Are you sure you want to delete this list? ',
    toBeDeleted: ' athletes are linked to it',
    toBeDeletedSingular: ' athlete is linked to it',
    deleteMessage: 'Are you sure you want to delete the list ',
    text1: 'Mark as agency',
    text2: 'Does your agency represent this athlete? Tag him as one of your athletes.',
    text3: 'Mark as my agent',
    text4: 'Report',
    text5: "Is this not this athlete's agent? Let us know so we can correct the information.",
    text6: 'Send report',
    text7: 'Unmark as agency',
    text8: 'Not my agent',
    text9: 'Your agency ',
    text10: ' represents this athlete? Unmark it as one of your athletes.',
    text11: 'NO',
    text12: 'New list name',
  },

  report: {
    gene_in: 'Generated in ',
    project: 'Project ',
    relat: 'Athlete Report',
    gene_for: 'Generated by ',
    pdf: 'Generating PDF...',
    ex_relat: 'Export Report',
    relat_error: 'There was a problem generating the time shadow for the report, please try again',
    success: 'Your report has been successfully configured!',
    generate: 'Click on the button below to print or generate a PDF.',
    export_pdf: ' Export to PDF',
    print_out: 'Print/Export report',
    reportData: 'Report Data',
    reportName: 'Report name',
    position: 'Position',
    athlete: 'Athlete',
    positions: 'Positions',
    appAccessAuthError: 'You are trying to export a report with a user other than the one logged in on the WEB, please log out of your WEB account and try again.',
  },

  slider_menu: {
    in: 'Between',
    de: '(From):',
    ate: '(To):',
    clean: 'Clean',
    e: 'and',
    seas: 'Season',
    todas: 'All',
    season_all: 'Season: All',
    age_all: 'Age: All',
    value_all: 'Value: All',
    team_all: 'Team: All',
    competition_all: 'Competition: All',
    group_by: 'Group by: ',
    criterion: 'No criteria',
    value_sale: 'Value for Sale (in €)',
    value_salary: 'Anual salary (in €)',
  },

  filters: {
    add_athlete: 'Add athlete',
    active_filters: 'Active filters:',
    apply: 'Apply',
    custom_athletes: 'Search by Athletes',
    custom_athletes_applied: 'From my organization',
    custom_athletes_unapplied: 'From Footlink',
    key: 'Key-Word',
    profile: 'Profile',
    min_sm: 'Min.',
    mini: 'Minimum',
    minima: 'Minimum',
    ate: 'To',
    de: 'From',
    select_period: 'Select period',
    pdf: 'PDF',
    video_link: 'Video',
    a: 'at',
    max_sm: 'Max.',
    max: 'Maximum',
    maxima: 'Maximum',
    games_in: 'Games in',
    and_max: 'and maximum',
    contract: 'Contract',
    pos: 'Position',
    nation: 'Nationality',
    gender: 'Gender',
    lists: 'Monitoring Athlete',
    lists_default: 'On any list',
    games: 'Games in the Season',
    gols: 'Goals in the Season',
    cbf_num: 'CBF number',
    age: 'Age',
    birt_year: 'Year of birth',
    division_ope: 'Division in which it operates',
    country_ope: 'Country in which it operates',
    state_ope: 'State in which it operates',
    clean_filters: 'Clear Filters',
    contract_until: 'Contract until:',
    athl_pro: 'professional-athletes',
    athl: 'amateur-athletes',
    with_contr: 'with contract',
    no_contr: 'without contract',
    gen: 'gender',
    search: 'Search for Athletes',
    ath_base: 'Search for athletes in the most updated database of Brazil',
    ath: 'Athletes',
    custom_ath: 'Added by the organization',
    multiple: 'Multiple',
    order_by: 'Order by',
    byPriority: 'By priority',
  },

  agencies: {
    birth: 'Birth',
    name: 'Name',
    searchName: 'Agency name',
    email: 'Email',
    report_name: 'Report Name',
    athlete_name: 'Athlete name',
    club: 'Club',
    club_at_moment1: 'Club at the time',
    club_at_moment2: 'of evaluation',
    agency: 'Agency',
    agencies: 'Agencies',
    subti: 'Check the most complete and updated list of agents in Brazil',
    origin_countr: 'Country of origin',
    phones: 'Phones',
    lists: 'List',
    text1: 'athletes managed by this organization',
    text2: 'Current Club',
    text3: 'Current Contract',
    text4: 'Ceding club',
    text5: 'Assignor Agreement',
    text6: 'There are no athletes added to this agency yet',
    text7: 'Category',
    text8: 'FIFA License',
    text9: 'By category',
    text10: 'Verified agencies',
  },

  config: {
    dire: 'Targeting',
    cont: 'To hire',
    contr: 'contratar',
    mon: 'To monitor',
    moni: 'monitorar',
    discard: 'descartar',
    toEvaluate: 'Bring to evaluation',
    evaluate: 'trazer-para-avaliacao',
    pri: 'Priority',
    hi: "High",
    hig: 'alta',
    dang: 'danger',
    mid: 'Average',
    mi: 'media',
    warn: 'warning',
    lo: 'Low',
    low: 'baixa',
    inf: 'info',
    model: 'Model',
    prior: 'Prior.',
    CONTRATAR: 'Contract',
    MONITORAR: 'Monitor',
    OBSERVAR: 'Monitor',
    DESCARTAR: 'Discard',
    NEGOCIAR: 'Negotiate',
    'TRAZER-PARA-AVALIACAO': 'Bring to evaluation',
    ALTA: 'High',
    MEDIA: 'Average',
    BAIXA: 'Low',
  },

  field: {
    con: 'Conclude',
    edit: 'Edit',
    insert: 'Insert',
    click: 'Click here to enter your review',
    click1: 'Click here to enter information.',
    graf: 'Aspect Chart',
    pare: 'Seem',
    conte: 'Context of the team/league in which it operates',
    ava: 'Athlete Assessment',
    gameData: 'Game Data',
    selec: 'Select an assessment template.',
    asp: 'Aspects',
    re_ava: 'Assessments Performed',
    add_ava: 'Add Review',
    text1: 'This athlete was evaluated in',
    opor: 'opportunities',
    re_ava2:
      'Check out the reviews already conducted for this athlete by the analysts of your organization and by Footure Pro.',
    re_ava1: 'Evaluations',
    filter1: 'Analysts: All',
    filter2: 'Club at the moment: All',
    filter3: 'Period',
  },

  match: {
    text1: 'Upcoming Games',
    text2: "We still don't have a record of the athlete's next games",
    text3: 'Games',
    text4: 'Games played',
    text5: "We still don't have a record of the athlete's games",
    comp: 'Competition Name',
    mand: 'Principal',
    pla: 'Scoreboard',
    guest: 'Visitor',
    obs: 'Observer',
    sum: 'Match Summary',
    videos: 'Videos',
    text6: '+ SEE ALL VIDEOS',
    availability: 'Availability',
    text7: "Check the athlete's statistics by matches. Also plan to accompany the athlete in his next games.",
    text8: 'Athlete statistics in the match',
    text9: 'Goals',
    text10: 'Yellow card',
    text11: 'Two Yellows',
    text12: 'Red card',
    text13: 'Minutes Played',
    text14: 'Started as a Titular',
    text15: 'Started on the bench',
    text16: 'In',
    text17: 'Out',
    text18: 'Matches Played',
    text19: 'Two yellow cards',
    text20: 'Entered the game',
    text21: 'Left the game',
    text22: 'Assists',
    noResults1: 'No matches found',
    noResults2: 'with these criteria',
  },

  overview: {
    text1: '+ See all transfers',
    text2: 'Career',
    text3: 'Total moved:',
    text4: 'See Activity',
    text5: '+ SEE ALL REVIEWS',
    text6: '+ SEE ALL TRADES',
    text7: 'Negotiations',
    text8: 'completed',
    text9: 'canceled',
    text10: 'pending',
    text11: 'Start',
    text12: 'Responsible',
    text13: 'Last Status',
    text14: 'Contact',
    text15: 'Type',
    text16: 'Status',
    text17: 'News',
    text18: '+ VIEW ALL NEWS',
    text19: '+ See full performance',
    text20: 'Performance',
    text21: 'Close',
    text22: 'Try again',
    text23: 'Performance in the last 5 competitions',
    text24: 'Report',
    text25: 'Last 3 transfers',
    text26: 'Availability',
    text27: 'Career Goals',
    text28: 'Career Games',
    text29: 'Highest value',
    text30: ' on ',
    text31: 'Athlete information',
  },

  report_pro: {
    title: 'Reports PRO',
    description: 'Check out the reports generated by professionals about the athlete. Insert your report too.',
    no_reports: 'There are no reports to display',
    image: 'Image',
    add_report: 'Add report',
    edit: 'Change',
    dialog_h1: 'Report data',
    dialog_title: 'Title',
    dialog_type: 'File type',
    dialog_type_pdf: 'PDF file',
    dialog_type_video: 'Link to video',
    file: 'File',
    video: 'Link to video',
    select_file: 'Select file',
    access: 'access',
    public: 'Public',
    only_agency: 'Only my organization sees',
    when_see: 'Who can see?',
    when_see_agency: 'Only my organization',
    delete_report: 'Delete Report',
    confirm_delete_report: 'Are you sure you want to delete this report?',
    filter_type: 'By type',
    filter_period: 'By period',
    filter_origin: 'By origin',
    filter_myagency: 'Only inserted by me',
    button_add: 'Add report PRO',
  },

  myAgency: {
    recomendedDimensionLogo: 'Recommended logo size: 200x200 px',
    agency_info: 'Agency Information',
    athletes_in: 'Athletes on Footlink',
    principal_market: 'Principal Market',
    my_athletes: 'My Athletes',
    my_athletes_desc: "Manage your agency's athlete information",
    athletes_profile: 'Athletes Profile',
    represent: 'Representation',
    transfer: 'Transfers',
    dialog_title: 'Agency details',
    edit_agency: 'Edit Agency data',
  },

  myClub: {
    negotiations: {
      negotiations: 'Market Management',
      originTransferMarket: 'Transfer Market Origin',
      subtitle: 'Keep track of the deals carried out by your club.',
      exit: 'Exits',
      arrivals: 'Arrivals',
      allPositions: 'Position: All',
      inConversation: 'In conversation',
      discarded: 'Discarded',
      inNegotiation: 'In negotiation',
      negotiated: 'Negotiated',
      addAthlete: '+ Add Athlete',
      list: 'List',
      report: 'Report',      
      model: 'Model',
      direction: 'Direction',
      priority: 'Priority',
    },
    general: {
      title: 'Overview',
      contractTitle: 'Expiring contracts',
      contractSeeMore: 'See all contracts',
      info: 'Club Information',
      emblem: 'Emblem',
      addImage: 'Add Image',
      clubData: 'Club Data',
      stadium: 'Stadium',
      partnersNumber: 'Partners Number',
      expireIn: 'Expire in',
      dialogTitle: 'Edit Club Details',
    },
    users: {
      title: 'Users',
      subtitle: "Manage and control which users can access your organization's account",
      remove: 'Remove',
      editPermission: 'Edit permissions',
      cancel: 'Cancel',
      removeUser: 'Remove User',
      warningMessage: 'Are you sure you want to remove the user',
      errorMessage: 'An error occurred while trying to remove the user. Please try again',
    },
    professionalCast: {
      pageTitle: 'My Cast',
      pageSubTitle: "Manage the information of your club's athletes.",
      contractUntil: 'Contract until',
      cedingClub: 'Ceding club',
      cedingContract: 'Ceding contract',
      loanedClub: 'Loaned club',
      loanedContract: 'Loaned contract',
    },
    profileCast: {
      title: 'Cast Profile',
      pageTitle: 'Professional Cast Profile',
      pageSubTitle: "Keep track of the squad's age profile and their time on the pitch.",
      male: 'Male',
      female: 'Female',
      filterBy: 'Filter by: ',
      development: 'Development',
      cutting: 'Cutting',
      performance_peak: 'Performance Peak',
      sustain: 'Sustain',
      tutor: 'Tutor',
      minutes_played_label: 'Minutos Played',
      age_label: 'Age',
      info_label: '* This chart is limited to 40 athletes.',
      athlete_all: 'Athlete: All',
    },
    contracts: {
      pageTitle: 'Contracts',
      pageSubTitle: 'Track the expiration of the contracts of the athletes in your squad.',
      contractGroup_finished: 'Without contract',
      contractGroup_danger: 'Less than {{contractHighRisk}} months from expiration',
      contractGroup_warning: 'Between {{contractHighRisk}} and {{contractWarningRisk}} months from maturity',
      contractGroup_ok: 'More than {{contractWarningRisk}} months from expiration',
      contractGroupEmpty_finished: 'No expired contracts',
      contractGroupEmpty_danger: 'No contracts less than {{contractHighRisk}} months from expiry',
      contractGroupEmpty_warning:
        'No contracts between {{contractHighRisk}} and {{contractWarningRisk}} months from expiration',
      contractGroupEmpty_ok: 'No contracts more than {{contractWarningRisk}} months past due date',
      contractResumeCount_finished: '{{length}} athletes',
      contractResumeCount_danger: '{{length}} contracts',
      contractResumeCount_warning: '{{length}} contracts',
      contractResumeDesc_finished: 'without contract',
      contractResumeDesc_danger: 'with High Risk',
      contractResumeDesc_warning: 'with Medium Rick',
      dialogTitle: 'Edit Contract Expiration Alerts',
      dialogDesc: 'Edit contract expiry alert times (in months)',
      riskTitle_danger: 'High Risk:',
      riskTitle_warning: 'Medium Risk:',
      riskTitle_ok: 'Low Risk:',
      riskDescLessThan: 'Less than',
      riskDescGreatherThan: 'Greather than',
      riskDescMonths: 'months',
      riskDescAnd: 'and',
      tableColumn: {
        athlete: 'Athlete',
        age: 'Age',
        position: 'Position',
        nationality: 'Nationality',
        sourceTeam: 'Assignor Club / Assignor Club Agreement',
        contractType: 'Type of contract',
        contractNumber: 'Contract',
        contractStart: 'Beginning of the Contract',
        contractEnd: 'End of contract',
      },
    },
  },

  club: {
    text1: 'Deadline of the contract:',
    text2: 'Loaned:',
    text3: 'Yes',
    text4: 'No',
    text5: 'No reviews',
    text7: 'CURRENT MARKET VALUE',
    text8: 'HIGHER MARKET VALUE',
    text9: 'uninformed',
    text10: 'Edit athlete data',
    text11: 'Agency',
    text12: 'Uninformed',
    text13: 'Height',
    text14: 'Weight',
    text15: 'Technical evaluation',
    text16: 'Performance',
    text17: 'Club/National Team',
    text18: 'Check the athletes performance history by season, competition, club, and national teams.',
    box1: 'We found nothing with these terms.',
    box2: 'Report data:',
    box3: 'Report generated for the project',
    box4: 'Description',
    box5: 'Maximum 250 characters',
    box6: 'Report Content',
    box7: 'Selected Athletes',
    box8: 'Add athlete',
    box9: 'Total Season ',
    box10: 'General features',
    box11: 'Negotiation',
    box12: 'Agent',
    box13: 'Wage',
    box14: 'Investimento',
    box15: 'Assessment',
    box16: 'Context in which it operates',
    box17: 'There are no reviews for this athlete yet.',
    box18: 'Untitled',
    box19: "Athlete's Career History",
    box20: 'Transfers',
    box21: 'CURRENT CLUB',
    box22: 'Until ',
    box23: 'ASSIGNOR CLUB',
    box24: 'Overview',
    box25: 'Notes',
    box26: 'Assessments',
    box27: 'My ads',
    box28: 'Athletes available',
    box29: 'My negotiations',
    box30: 'Desired athletes',
    noData: 'There are no athletes to display',
  },

  text: {
    box1: 'Loan Club.',
    box2: 'Account Loan.',
    box3: 'Contacts',
    box4: 'This club was rated at',
    box5: 'Monitoring',
    box6: 'News',
    box7: 'Add news',
    box8: 'Most recent competitions',
    box9: 'Placing',
    box10: '+ SEE ALL COMPETITIONS',
    box11: 'Source',
    box12: 'Foundation',
    box13: 'Official site',
    box14: 'Partners',
    box15: 'Address',
    box16: 'Zip code',
    box17: '+ SEE ALL CONTACTS',
    box18: 'Managers',
    box19: 'Stadium',
    box20: '+ VIEW ALL DOWNLOADS',
    box21: 'cast',
    box22: 'Account Assignor.',
    box23: 'MARKET VALUE',
    box24: 'Option 1',
    box25: 'Option 2',
    box26: 'Option 3',
    box27: 'Period',
    box28: 'Local',
    box29: 'Result',
    box30: 'Cast',
    box31: 'Loaned',
    box32: 'Amateurs',
    box33: 'Feminine',
    box34: 'State',
    box35: 'City',
    box36: 'Country',
    box37: 'Amateurs',
    box38: 'Loaned',
  },

  compe: {
    text1: 'Country',
    text2: 'Scope',
    text3: 'Current',
    text4: 'Competition Club',
    text5: 'Registered Athletes',
    text6: 'No matches found',
    text7: 'Posted in ',
    text8: 'Follow the highlights of ',
    text9: ' with reports produced by Footure PRO.',
    text10: 'No reports found',
    text11: 'Reports',
    text12: 'Clubs',
    text13: 'Artillery',
    text14: 'See summary',
    text15: 'Watch video',
    text16: 'No games found',
    text17: 'Games in competition',
    text18: 'Assignor',
    text19: 'Subscribers',
    text20: 'Cast in the competition',
    text21: 'Organizer',
    text22: 'Region',
    text23: 'End',
    text24: 'Competitions',
    text25: "We are the largest source of grassroots and women's football competitions in Brazil.",
    text26: 'Professional',
    loadMore: 'Load more games',
    precociousAthletes: 'Precocious Athletes',
    context: {
      R: 'Regional',
      Regional: 'Regional',
      N: 'National',
      Nacional: 'National',
      SA: 'South American',
      Sulamericano: 'South American',
    },
    currentSeason: 'Current season',
  },

  organi: {
    box1: 'Update',
    box2: 'Avatar',
    box3: 'Organization name',
    box4: 'General Email',
    box5: 'Phone 1',
    box6: 'Phone 2',
    box7: 'Main Address',
    box8: 'Complete address',
    box9: 'City',
    box10: 'Social media',
    box11: 'Update settings',
    box12: 'Phone 1 needs to have DDI, DDD and number.',
    box13: 'Phone 2 needs to have DDI, DDD and number.',
    box14: 'Whatsapp needs to have DDI, DDD and number.',
    box15: 'Avatar',
    box16: 'Stadium Name',
    box17: 'Popular Name',
    box18: 'Foundation date',
    box19: 'Number of Members',
    box20: 'Select',
    text1: 'Settings',
    text2: 'Manage your account and preferences',
    text3: 'Charge',
    text4: 'Organization',
    text5: 'Delete review',
    text6: 'Realized in',
    text7: 'Monitor and get quick access whenever you need it.',
    text8: 'Delete Reviews',
    text9: 'Confirm',
    text10: 'Are you sure you want to delete selected reviews?',
    text11: 'Division',
    text12: 'Series',
    text13: 'Athletes',
    text14: 'Market value',
    text15: 'Foreign Clubs',
    text16: 'Search athletes and club information from Brazil and the World',
    text17: 'started to ',
    text18: 'the athlete',
    text19: 'generated a new report',
    text20: 'In ',
    text21: ' at ',
    text22: 'Athlete advertised',
    text23: 'Type of Business',
    text24: 'Value',
    text25: 'Publication',
    text26: 'Expiration',
    text27: 'Interested parties/Views',
    text28: 'Status',
    text29: 'of',
    text30: "sent a message about the athlete's announcement",
    text31: 'added',
    reportPro: ' Report PRO "{{report_name}}" of ',
    evaluationSaved: 'Rating saved successfully',
    desiredAthlete: 'Desired Athlete',
    inNegotiationsOferreds: 'Under negotiation / Offered',
  },

  home: {
    support: 'Contact support',
    box1: 'registered',
    box2: 'of the athlete',
    box3: 'at the CBF IDB',
    box4: 'In: ',
    box5: 'Until: ',
    box6: 'Release',
    box7: 'Registration for sports initiation',
    box8: 'Termination',
    box9: 'Reversal',
    box10: 'Password',
    box11: 'Forgot my password',
    box12: 'Stay connected',
    box13: 'Wait',
    box14: 'Login to my account',
    box15: 'Enter your email',
    box16: 'Recover Password',
    box17: 'Back',
    box18: 'We sent you an email to generate a new password.',
    box19: 'Remove Athletes',
    box20: 'Remove',
    text1: 'Are you sure you want to remove all selected athletes from the watch lists?',
    text2: 'By state',
    text3: ' - National',
    text4: 'Brazilian Clubs',
    text5: 'All',
    text6: 'Division A',
    text7: 'Division B',
    text8: 'Division C',
    text9: 'Division D',
    text10: 'Projects',
    text11: 'Manage and organize your projects collaboratively',
    text12: 'Create your first project',
    text13: 'Add Project',
    text14: 'The new password and confirmation password must be the same',
    text15: 'Current password',
    text16: 'New Password',
    text17: 'Confirm new password',
    text18: 'My profile',
    text19: 'Change Password',
    text20: 'Manage your profile and preferences',
    footlinkClubs: 'Footlink Clubs',
    footlinkClient: 'Footlink Client',
  },

  proj: {
    showArchived: 'Show archived projects',
    campo: 'Field',
    linkProjetos: 'Projects',
    linkRelatorios: 'Reports',
    linkMonitoramento: 'Monitoring',
    linkAvaliacoes: 'Evaluation',
    linkJanelasTransferencias: 'Transfer Windows',
    linkMercadoTransferencia: 'Market of transfers',
    subMercadoTransferencia: 'Negotiate athletes directly and with more transparency.',
    archive: 'Archive',
    unarchive: 'Unarchive',
    archived: 'ARCHIVED',
    box1: 'View profile',
    box2: 'Edit Report',
    box3: 'Comment History',
    box4: 'Import selected comments',
    box5: 'Import',
    box6: 'ADDED BY',
    box7: 'INDICATED BY',
    box8: 'in 00/00/000',
    box9: 'Indicator by',
    box10: 'Test',
    box11: 'Comments',
    box12: 'You already did',
    box13: "comments within that athlete's profile.",
    box14: 'See comments',
    box15: 'Owner',
    box16: 'created this project',
    box17: 'There is no history to display',
    box18: 'Monitoring',
    box19: 'There was a problem syncing Workflow, please refresh your page',
    box20: 'athletes',
    text1: '+ Add athlete',
    text2: '+ Add list',
    text3: 'Create your first Shadow Team',
    text4: 'Add shadow team',
    text5: 'See shadow team',
    text6: 'Team Shadow',
    text7: 'Scheme',
    text8: 'No athletes to display',
    text9: 'Type a title',
    text10: 'Select schema: ',
    text11: 'There was a problem communicating with the server, please try again later',
    text12: 'No athletes defined for this position',
    text13: 'Please select the desired position for the report',
    text14: 'Select Athlete',
    text15: 'Stage',
    text16: 'Athletes in this position',
    text17: 'Test 1',
    text18: 'Test 2',
    text19: 'Test 3',
    text20: '...Loading',
    text21: 'Workflow',
    text22: 'Organize and customize your market analysis process',
    text23: 'Schema',
    text24: "Athlete's Name",
    create: 'Create',
    proj: 'Project',
    pageTitleTimeSombra: 'Lineups',
    pageDescTimeSombra: 'Create lineup from selected athletes in your project.',
    filtrarPor: 'Filter by: ',
    filtarPorPlaceholder: 'Schema name',
    ultimaAtualizacao: 'Modified at: ',
    montarTimeSombra: 'Assemble Lineup',
    montarTimeSombraDesc: 'Choose your field model, scheme and color of the field and assemble your Lineup.',
    nomeTimeSombra: 'Lineup name: ',
    corDoCampo: 'Field Color: ',
    corDoCampoVerde: 'Green',
    corDoCampoEscuro: 'Dark',
    corDoCampoBranco: 'White',
    salvarContinuar: 'Save and continue',
    esquema: 'Schema: ',
    modeloDeCampo: 'Field Model: ',
    modeloStandard: 'Standard',
    modeloArea: 'Area',
    modeloRunner: 'Runner',
    editarTimeSombra: 'Edit Shadow Team',
    adicionarPosicao: 'Add Position',
    editarPosicao: 'Position ',
    posicaoPanelTitle: 'Athletes of position ',
    posicaoInputNome: 'Position name',
    adicionarAtleta: 'Add Athlete',
    atletaPanelTitle: 'Select Athlete',
    atletaFiltroNome: 'Athlete Name',
    atletaFiltroEtapaTodos: 'Step: All',
    atletaFiltroPosicaoTodos: 'Position: All',
    atletaCorAmarelo: 'Yellow',
    atletaCorVerde: 'Green',
    atletaCorAzul: 'Blue',
    atletaCorVermelho: 'Red',
    atletaCorCinza: 'Gray',
    erroSchema: 'Please define the desired Scheme',
    erroNome: 'Please define the desired Name',
    historyTitle: 'History',
    historySubTitle: 'Keep control over all activities of your project.',
    userTitle: 'Users',
    userSubTitle: 'Manage the team working on the project.',
    deleteTitle: 'Delete list',
    deleteMessage:
      'The deletion of the column will delete all athletes from it and this action cannot be undone. Are you sure you want to proceed with the deletion?',
    applyFilters: 'Apply',
    modalUploadImagem: 'Recommended resolution',
    indicatedByEditing: ' (Click to change)',
    confirmationDeleteModal: 'Are you sure you want to delete this whole project?',
    orderCreatedAtAsc: 'Created - Oldest',
    orderCreatedAtDesc: 'Created - Latest',
    orderUpdatedAtAsc: 'Updated - Oldest',
    orderUpdatedAtDesc: 'Updated - Latest',
    orderNameAsc: 'Alphabetical order - A > Z',
    orderNameDesc: 'Alphabetical order - Z > A',
    removeAthlete: 'Remove athlete',
    removeAthleteMessage: 'Are you sure you want to remove this athlete?',
    athleteProjectCount: "It's present in {{count}} project",
  },

  user: {
    box1: 'Email successfully sent!',
    box2: 'Please provide a valid email',
    box3: 'Search by name',
    box4: 'Permission',
    box5: 'Add',
    box6: 'Not listed above? Invite by email.',
    box7: "Enter the person's email",
    box8: 'Edit Users',
    box9: 'Few minutes ago',
    box10: 'hours ago',
    box11: 'days ago',
    box12: 'Added to project at',
    box13: 'Last activity',
    box14: 'People',
    box15: 'Add or Edit Person',
    box16: 'Created in ',
    box17: 'Last update on ',
    box18: 'by',
    box19: 'Viewer',
    box20: 'Administrator',
    text1: 'Editor',
    text2: 'History',
    text3: 'Users',
    text4: 'nothing to show off',
    text5: 'Project name',
    text6: 'Create Project',
    text7: 'No athletes added',
    text8: 'See report',
    text9: 'Athletes in the Report',
    text10: 'Delete Reports',
    text11: 'Delete',
    text12: 'Are you sure you want to delete selected reports?',
    text13: 'Your password has been changed. Log in using the new password.',
    text14: 'Enter new password',
    text15: 'Confirm new password',
    text16: 'Male',
    text17: 'Amateur',
    text18: 'Open',
    text19: 'Closed',
    text20: 'By Country',
    text21: 'Modality',
    text22: 'By Modality',
    text23: 'By Status',
    text24: 'Start Season',
    text25: 'End Season',
    text26: 'Window 1 (Start/End)',
    text27: 'Window 2 (Start/End)',
    text28: 'Window 3 (Start/End)',
    text29: 'Windows',
    text30: 'Stay on top of transfer windows for all countries',
    text31: 'Athlete name',
    text32: 'My ads',
    text32sub: 'Maximize your athlete trading opportunities with our ad management solution.',
    text33: 'Add Advertisement',
  },

  login: {
    alreadyLoggedText1: 'Your Footlink account is in use on another device.',
    alreadyLoggedText2: "For your security, we don't allow simultaneous access with the same account.",
    alreadyLoggedText3: 'Do you want to force login and close all open sessions?',
    forceLogin: 'Yes, force login',
  },

  app: {
    box1: 'See all activities',
    box2: 'Notifications and Alerts',
    box3: 'More notifications',
    box4: 'See club',
    box5: 'Exit',
    box6: 'See all',
    box7: 'Enter the name of an athlete or club (at least 3 characters)',
    box8: 'ADVANCED SEARCH',
    box9: 'Active windows',
    box10: 'See all windows',
    box11: 'SPECIAL',
    box12: 'PRO',
    box13: 'Home page',
    box14: 'Athletes and Clubs',
    box15: 'Base',
    box16: 'Competitions',
    box17: 'Copinha 2023',
    box18: 'Market analysis',
    box19: 'Agency Listing',
    box20: 'Report Configuration',
    text1: 'Edition',
    text2: 'Preview',
    text3: 'Saving changes...',
    text4: 'Changes successfully saved!',
    text5: 'in',
    text6: 'per page',
    text7: 'Items',
    text8: 'Chat',
    text9: 'Create announcement',
  },

  preferentialFoot: {
    null: '',
    Ambos: 'Both',
    Direito: 'Right',
    Esquerdo: 'Left',
    Indiferente: 'indifferent',
    undefined: 'Uninformed',
  },

  performance: {
    ABAIXO: 'Below the match level',
    MEDIO: 'Average level of the match',
    ACIMA: 'Above the match level',
    MUITO_ACIMA: 'Much above the match level',
    null: '',
    undefined: 'Not informed',
  },

  positionOptions: {
    null: '',
    Atacante: 'Forward',
    Ataque: 'Attack',
    Centroavante: 'Center forward',
    Defesa: 'Defense',
    Goleiro: 'Goalkeeper',
    LateralDireito: 'Right-back',
    LateralEsquerdo: 'Left-back',
    Meia: 'Midfielder',
    MeiaAtacante: 'Attacking midfielder',
    'Meia-atacante': 'Attacking midfielder',
    MeioCampo: 'Midfielder',
    Volante: 'Defensive midfielder',
    Zagueiro: 'Defender',
    ExtremoEsquerdo: 'Left wing',
    ExtremoDireito:'Right wing',
    'Lateral Direito': 'Right-back',
    'Lateral Esquerdo': 'Left-back',
    'Meio-campo': 'Midfielder',
    'Extremo Esquerdo': 'Left wing',
    'Extremo Direito':'Right wing',
    Outro: 'Other',
    undefined: 'Uninformed',
  },

  positionOptionsRaw: {
    null: '',
    Atacante: 'Forward',
    Ataque: 'Attack',
    Centroavante: 'Center forward',
    Defesa: 'Defense',
    Goleiro: 'Goalkeeper',
    'Lateral Direito': 'Right-back',
    'Lateral Esquerdo': 'Left-back',
    Meia: 'Midfielder',
    'Meia-atacante': 'Attacking midfielder',
    'Meio-campo': 'Midfielder',
    Volante: 'Defensive midfielder',
    Zagueiro: 'Defender',
    undefined: 'Uninformed',
  },

  newDivisionOptions: {
    1: '1st Division',
    2: '2nd Division',
    3: '3rd Division',
    4: '4th Division',
    division: 'Division',
    UmDivisao: '1st Division',
    DoisDivisao: '2nd Division',
    TresDivisao: '3rd Division',
    QuatroDivisao: '4th Division',
    SemDivisao: 'Without Division',
  },

  errorMessage: {
    expiredAccount: 'Your account has expired, please contact us to renew your access. You can find our contact via the website footlink.app',
    serviceUnavailable: 'Unavailable service. Try again later.',
    defaultCredentialsError: 'We were unable to validate your credentials. Try again.',
    resourceNotFoundAth: 'Athlete not found.',
    resourceNotFoundClub: 'Club not found.',
    maximumAthleteReached: 'Maximum number of athletes in agency reached.',
    minMax: 'Max must be greater than min',
  },

  SystemInfoName: {
    teams: 'Ti',
    athletes: 'F',
    market_value: 'M',
    competitions: 'M',
    transfers: 'Transfers of athletes outside Brazil',
    bid: 'Contracts - CBF BID',
    historical_data_prof: 'Game stats (Pro)',
    historical_data_base: 'Game stats (Base)',
    match_data: 'M',
    transfer_windows: 'Transfer Windows',
  },

  yesNo: {
    nao: 'No',
    sim: 'Yes',
  },

  reportCheckboxes: {
    generalFeatures: 'General features',
    negotiation: 'Negotiation',
    images: 'Images',
    evaluations: 'Evaluation',
    historic: 'Performance',
    career: 'Career',
  },

  targetingOptions: {
    Contratar: 'Hire',
  },

  radioAdvancedSearch: {
    female: 'Female',
    male: 'Male',
  },

  radioAdvancedSearchCustomAthlete: {
    custom: 'Added by my organization',
    normal: 'From the Footlink Athlete Bench',
  },

  contractOptions: {
    'com-contrato': 'With Contract',
    'sem-contrato': 'Without contract',
    'indiferente': 'Indifferent',
  },

  profileOptions: {
    'atletas-profissionais': 'Professional Athletes',
    'atletas-amadores': 'Amateur Athletes',
  },

  negotiationOptions: {
    SALE: 'Sale',
    LOAN: 'Loan',
  },

  desiredNegotiationOptions: {
    SALE: 'Purchase',
    LOAN: 'Loan',
  },

  monitoredAthlete: {
    monitorado: 'Yes',
    naomonitorado: 'No',
  },

  europeanPassport: {
    'possui-passaporte': 'Yes',
    'nao-possui-passaporte': 'No',
  },

  erros: {
    text1: 'Data not found.',
    text2: 'Unable to search for agencies at this time.',
    text3: 'Unable to fetch agency details at this time',
    text4: 'It was not possible to fetch athletes from this agency at the moment.',
    text5: 'Athlete tagged to your agency successfully!',
    text6: 'Report sent successfully!',
    text7: 'Athlete successfully unmarked!',
    text8: 'Unable to perform the action at the moment',
    text9: 'Athlete',
    text10: 'Assessment load failed.',
    text11: 'An error occurred while trying to fetch the grades. Please try again',
    text12: 'An error occurred while trying to create the note. Please try again',
    text13: 'It was not possible to find the list of athletes.',
    text14: "The athlete's performance history could not be found.",
    text15: "Could not find the athlete's games.",
    text16: "It was not possible to find the athlete's CBF contract history.",
    text17: "We were unable to find the player's transfer history.",
    text18: 'An error occurred while trying to update the athlete. Please try again',
    text19: 'Could not find the list of countries.',
    text20: 'We were unable to fetch reviews at this time. Please try again',
    text21: 'Unable to delete reviews at this time. Please try again',
    text22: 'An error occurred when trying to fetch the last viewed athletes. Please try again',
    text23: 'An error occurred when trying to fetch the latest moves. Please try again',
    text24: 'An error occurred while trying to fetch system details. Please try again',
    text25: 'An error occurred while trying to fetch transfer windows. Please try again',
    text26: 'Could not find the watchlists.',
    text27: 'Unable to save watchlist.',
    text28: 'Unable to change watchlists.',
    text29: 'Unable to add to monitoring',
    text30: 'An error occurred while trying to update the organization. Please try again',
    text31: 'Unable to fetch projects at the moment',
    text32: 'Unable to fetch project details at this time',
    text33: 'An error occurred while trying to fetch the reports. Please try again',
    text34: 'An error occurred while trying to fetch the report. Please try again',
    text35: 'An error occurred when trying to generate the report PDF. Please try again',
    text36: 'An error occurred while trying to generate the report. Please try again',
    text37: 'An error occurred when trying to update the report. Please try again',
    text38: 'Unable to delete reports at this time. Please try again',
    text39: 'Could not find the list of teams.',
    text40: 'Filter data not found',
    text41: 'Invalid username and/or password',
    text42: 'Invalid Data',
    text43: 'An error occurred while trying to fetch profile data. Please try again',
    text44: 'An error occurred while trying to update the profile. Please try again',
    text45: 'Password successfully updated!',
    text46: 'An error occurred while trying to change the password. Please try again',
    text47: 'Assessment schema load failed.',
    text48: 'Competition entry data not found.',
    text49: 'The data is incorrect. Try again.',
    text50: 'Image type not supported. Upload an image with the following formats: *.png ou *.jpg ou *.jpeg ou *.gif ou *.bmp ou *.webp.',
    link: 'Social networks/website must be completed with a valid link',
    generalForm: 'All fields with * must be filled in',
    ageMinMax: 'The minimum age must be lower than the maximum age',
  },

  rest: {
    box1: 'Crop image for upload',
    box2: 'Make the first note for the athlete',
    box3: 'Enter your observation',
    box4: 'In this project',
    box5: 'Grade',
    box6: 'Attachments',
    box7: 'Add file',
    box8: 'Include Opinion',
    box9: 'Add Context',
    box10: 'Include aspects',
    box11: 'Include microaspects',
    box12: 'Select any review from the list',
    box13: "There is no data available about the athlete's career",
    box14: 'There is no data available on athlete transfers',
    box15: "There is no data available on the athlete's contracts",
    box16: 'Edit Image',
    box17: 'Insert Image',
    box18: 'analyzed athletes',
    box19: 'There was a problem removing the athlete, please try again',
    box20: 'There was a problem updating athlete data, please try again',
    box21: 'athlete',
    box22: 'Delete all',
    box23: 'No annotation inserted.',
    box24: 'Step 1: Select the athlete to be announced',
    box24other: '* You can only advertise athletes without a contract',
    box25: 'Continue',
    box26: 'Audience',
    box27: 'Selected clubs',
    box28: 'Publication period',
    box29: 'Desired type of negotiation',
    box30: 'Selling value',
    box31: 'Annual athlete salary',
    box32: 'Observations',
    box33: 'Loan value',
    box34: 'Search',
    box35: 'Add new list',
  },

  trello: {
    box1: "Learn about the world's biggest clubs and their athletes.",
    box2: "Still don't have access? Request a demo.",
    box3: 'Find out more',
    box4: 'The Future of your game passes through here.',
    box5: 'Check out information about Brazilian clubs and their squads.',
    box6: 'Check out the clubs that are Footlink customers.',
  },

  languages: {
    br: 'Português (Brasileiro)',
    pt: 'Português',
    eng: 'English',
    esp: 'Español',
  },

  professionals: {
    topbarTitle: 'Professionals Network',
    topbarSubtitle: 'Manage and develop your network with the largest network of football professionals in Brazil.',

    filtersTopspan: 'We are already a network of 1,450 professionals.',
    filtersInputName: 'Name',
    filtersSelectLevel: 'Level: All',
    filtersSelectOrganization: 'Organization: All',

    connectButton: 'Connect',
    connectedButton: 'Connected',
    pendingButton: 'Pending',
    sendMessageButton: 'Send message',

    inviteTitle: 'Invites',
    inviteSpan: 'Invite your contacts to be part of the largest\n network of football professionals in Brazil.',
    inviteButton: 'Send invite',
    inviteAccept: 'Accept',
    inviteIgnore: 'Ignore',
    invite: 'There are not invitations to display ',

    networkTitle: 'My Network',
    networkSpan: 'Manage and expand your network with Footure Pro.',
    networkConnections: 'Connections',
    networkFollow: 'Follow',
    networkFollowing: 'Following',
    networkFollowers: 'Followers',

    description: 'Description',
    socialMedia: 'Social Media',
    language: 'Languages',
    connections: 'Close connections',
    commonConnections: '+ See all common connections',
  },

  listTranslation: {
    Amateur: 'Amateur',
    Female: 'Feminine',
    Male: 'Masculine',
  },

  navDrawer: {
    myperfil: 'My perfil',
    organization: 'Organization',
    module: 'Modules',
    name: "Club's Name",
    perfil: 'see organization profile',
    feed: 'My Feed',
    search: 'Search for Athletes',
    club: 'Clubs',
    competitions: 'Competitions',
    analysis: 'Market Analysis',
    market: 'Transfer Market',
    rede: "Professional's Network",
    agencies: 'Agencies',
  },

  chat: {
    placeholder: 'Search Messages',
    from: 'You',
    select_message: 'Select a chat',
    view_club_page: 'View club page',
    view_athlete_page: 'View athlete page',
    view_agent_page: 'View agency page',
    organization_messages: 'Organization messages',
    my_messages: 'My messages',
    connections: 'Connections',
    negotiations: 'Negotiations',
    clubs: 'Clubs',
    athletes: 'Athletes',
    agents: 'Agents',
    message_clube: 'Message club',
    select_category: 'Select a category',
    no_chat: 'No chat found',
    talk_to_agent: 'Talk to agent',
    talk_to_club: 'Talk to club',
    athlete_club: 'Athlete Club',
    athlete_agency: 'Athlete Agency',
    contract_until: 'Until',
    deleted: 'This message has been deleted',
    deletedNote: 'This note has been deleted',
  },

  transferMarket: {
    offeredBy: 'Offered By',
    attention: 'Attention !',
    dialogMessage: 'When you show interest in an athlete, a new negotiation will start and your Club\'s name will be displayed in the negotiation even if it is "Anonymous".',
    wantToContinue: 'Do you want to continue?',
    search_page_desc: 'announced on the Transfer Market with these attributes at the moment',
    search_page_button: 'See Transfer Market',
    editAd: 'Edit Ad',
    filterTransferTypeSALE: 'Sale',
    filterTransferTypeLOAN: 'Loan',
    subtitle: 'Negotiate with the biggest clubs in Brazil and the world and strengthen your team.',
    available_negotiation: ' players available for negotiation',
    filterMessagesPlaceholder: 'Search messages',
    filterTransferTypePlaceholder: 'All negotiations',
    filterTransferTypeBuy: 'Arrivals',
    filterTransferTypeSell: 'Departures',
    headerClubTitle: 'Advertiser',
    headerPriceTitle: 'Asking price',
    headerTransferTypeTitle: 'Type of business',
    headerSalaryTitle: 'Salary',
    headerBuyTransferTypeText: 'Purchase',
    headerSellTransferTypeText: 'Sale',
    headerClubObs: 'Advertiser Notes',
    linkProfile: 'View full profile',
    chatMessageInput: 'Type your message',
    headerOtherChatTitle: '3 conversations about',
    headerOtherChatAlertTitle: 'There are {{{{length}}}} other open conversations about this player',
    headerOtherChatAlertLink: 'View conversations',
    headerChatInactive: 'This athlete is no longer on the transfer market',
    moreInfo: 'More Information',
    announcedIn: 'Announced In',
    business: 'Business',
    amountRequested: 'Asking price',
    sale: 'Sale',
    year: 'Year',
    clubAnnouncement: 'Ad Details',
    active: 'Active',
    inactive: 'Inactive',
    adData: 'Post Data',
    defineAudience: 'Define Audience',
    confirmAd: 'Confirm Post',
    publish: 'Publish',
    publishSuccess: 'Post published successfully!',
    step1title: 'Step 1: Enter the characteristics of the athlete you are looking for',
    step2title: 'Step 2: Enter your ad data',
    step3title: 'Step 3: Define who can see the ad',
    step4title: 'Step 4: Confirm ad data before publishing',
    step1error: 'Select an athlete to continue',
    step2error: 'Please fill in all fields before continuing',
    select: 'Select',
    selected: 'Selected',
    adAuciton: 'Ad Audience',
    showAll: 'Everyone can see',
    showAgents: 'Show ad for Agents',
    showAllClubs: 'All clubs can see',
    selectShow: 'Define who can see',    
    selectClubsShow: 'Set which clubs can see',    
    negotiationType: 'Desired negotiation type',
    sale_value: 'Selling price',
    sale_value_desired: 'Purchase price',
    loan_value: 'Loan value',
    annual_salary: 'Athlete annual salary',
    gross_salary: 'Gross Salary',
    perYear: 'per year',
    perMonth: 'per month',
    publishFail: 'Failed to publish ad!',
    completeProfile: 'Show complete profile',
    evaluationFooture: 'VALUATION FOOTURE',
    openChat: 'Open chats',
    athleteInfo: 'Athlete Information',
    modalError1: 'It is not possible to create more than one ad for the same athlete.',
    modalError2: 'Please, select an athlete to continue.',
    modalError3: 'Exceded plan limit.',
    modalError4: 'User logged in another device.',
    withoutClub: 'Without Club',
    announceType: 'What type of announcement do you want to create?',
    mySquadAthlete: 'Advertise athlete from my squad',
    AthleteProfile: 'Advertise desired athlete profile',
    gbe: 'GBE',
    moreCharacteristics: 'More characteristics',
    nameConfidential: 'Keep my club name confidential?',
    infoNameConfidential: 'When marked as "Yes" your club name will appear as "Confidential" in the announcement list. Your club\'s name will only appear to the clubs or agents of the athletes you show interest in.',
    atlheteOffers: 'Athlete Offers',
    athletesOffered: 'Athletes Offered',
    athletesInConversation: 'Athletes in conversation',
    discardedAthletes: 'Discarded Athletes',
    movedTo: 'This athlete has been moved to the tab ',
    showInterest: 'Show Interest',
    discard: 'Discard',
    interestedAgency: 'Interested Agency',
    interestedClub: 'Interested Club',
    clubObservations: 'Club Observations',
    maxValue: 'Maximum Value',
    maxSalary: 'Maximum Gross Salary',
    clubAnnounce: 'Club Announcement',
    desiredProfile: 'Profile of the desired Athlete',
    otherFeatures: 'Other features',
    advancedSearch: {
      desiredAthletes: 'Desired Athletes',
      subtitle: 'Negotiate with the biggest clubs in Brazil and the world and make great deals.',
      postedIn: 'Posted in',
      desiredPosition: 'Desired Position',
      age: 'Age',
      profile: 'Profile',
      sex: 'Sex',
      club: 'Club',
      clubSeries: 'Club Series',
      country: 'Country',
      business: 'Business'
    },
    athleteList: {
      youHave1: 'You have ',
      youHave2: ' athletes who fit the desired profile',
      offered: 'Athlete Offered',
      toOffer: 'Offer Athlete',
      goToNegotiation: 'Go To Negotiation'
    },
    textError1: 'No athletes have been offered so far',
    textError2: 'You do not have athletes with the profile sought by the club'
  },

  excededPlanLimit: {
    h4_1: 'Upgrade your account and',
    h4_2: 'have access to this and other modules!',
    h5_1: 'Check out the benefits of upgrading your Footlink account:',
    h5_2: 'And much more!',
    h6_1: 'Access to the largest database of Brazilian athletes on the market',
    h6_2: 'Access to updated athlete contract expiration data',
    h6_3: 'Access to the largest database of youth competitions in Brazil',
    h6_4: 'Tools to digitize and organize your market analysis process',
    button: 'Talk to a Footlink Consultant',
  },

  negotiations: {
    headerClubTitle: 'Advertiser',
    headerPriceTitle: 'Asking Price',
    headerTransferTypeTitle: 'Transfer Type',
    headerSalaryTitle: 'Salary',
    headerBuyTransferTypeText: 'Purchase',
    headerSellTransferTypeText: 'Sale',
    headerSaleTransferTypeText: 'Sale',
    headerLoanTransferTypeText: 'Loan',
    headerClubObs: 'Advertiser Notes',
    linkProfile: 'View Full Profile',
    chatMessageInput: 'Type your message',
    headerOtherChatTitle: '3 conversations about',
    headerOtherChatAlertTitle: 'There are 3 other open conversations about this player',
    headerOtherChatAlertLink: 'View conversations',
    errorChatCreate: 'Could not save your message, please check the message and try again',
    moreInfo: 'More Information',
    announcedIn: 'Announced In',
    business: 'Business',
    amountRequested: 'Amount Requested',
    sale: 'Sale',
    year: 'Year',
    clubAnnouncement: 'Ad Details',
    active: 'Active',
    inactive: 'Inactive',
    adData: 'Ad Data',
    defineAudience: 'Define Audience',
    confirmAd: 'Confirm Publication',
    publish: 'Publish',
    publishSuccess: 'Ad successfully published!',
    confidentialAd: 'Confidential Ad?',
    step1title: 'Step 1: Enter the characteristics of the athlete you are looking for',
    step2title: 'Step 2: Enter your ad data',
    step3title: 'Step 3: Define who can see the ad',
    step4title: 'Step 4: Confirm ad data before publishing',
    select: 'Select',
    selected: 'Selected',
    adAuciton: 'Ad Audience',
    showAll: 'Everyone can see',
    selectShow: 'Define who can see',
    negotiationType: 'Desired negotiation type',
    sale_value: 'Sale value',
    loan_value: 'Loan value',
    annual_salary: 'Player annual salary',
    perYear: 'per year',
    noMessages: 'There are no messages in this chat yet.',
    selectChat: 'Select a conversation to start negotiating!',
  },

  search: {
    typeNegotiation: 'Type of Negotiation',
    valueForSale: 'Value for Sale (in €)',
    valueForPurchase: 'Value for Purchase (em €)',
    salary: 'Salary (in €)',
  },

  divisionOptions: {
    division: 'Division',
    1: 'Division A',
    2: 'Division B',
    3: 'Division C',
    4: 'Division D',
  },

  transferWindows: {
    onlyMonitored: 'Only monitored',
    onlyOpen: 'Only open',
  },

  whatsapp: {
    phone: '5551995145721',
    valuation1: 'I would like to buy athlete valuation information ',
    valuation2: ' from ',
    upgrade: 'Hey! I want to upgrade my Footlink account!',
  },

  criteriaOptions: {
    '': 'No criteria',
    season: 'By season',
    competition: 'By competition',
    team: 'By team',
    teamInComp: 'By team in competition',
    biythyear: 'By birthyear',
    position: 'By position',
    minutes_played: 'Minutes Played',
    athlete__birthdate: 'Birthdate',
  },

  clubDivisionOptions: {
    undefined: 'Division',
    1: '1st Division - National',
    2: '2nd Division - National',
    3: '3rd Division - National',
    4: '4th Division - National',
  },

  agencyAthletes: {
    title: 'Agency athletes',
    subTitle: 'athletes in the portfolio of this agency',
  },

  firstAccess: {
    text1: 'Congratulations',
    text2: 'you have received one',
    text3: 'CBF license',
    text4: 'to connect to Footlink!',
    text5:
      'You are one step away from connecting to Footlink, Brazil`s most complete platform for managing your sports project.',
    text6: 'Activating your Footlink account is quick, simple and uncomplicated',
    text7: 'Shall we get started?',
    text8: 'Start account activation',
    text9: 'To begin',
    text10: 'enter the ',
    text11: 'temporary password',
    text12: ' which ',
    text13: 'you received by e-mail',
    text14: 'Temporary password',
    text15: 'Send',
    text16: 'Now enter the code',
    text17: 'you received in the e-mail below',
    text18: 'Code',
    text19: 'Didn`t receive the code? You can request it again in ',
    text20: 'Did you not receive the code?',
    text21: 'Invalid password or user is already active.',
    text22: 'Personal Data',
    text23: 'Visible only to people in your organization',
    text24: 'Create Password',
    text25: 'For your safety, change your sensibility frequently.',
    text26: 'It must contain numbers, letters and special characters and be at least 8 characters.',
    text27: 'This is in accordance with the terms of use and privacy policy',
    box1: 'Request it to be sent again',
    box2: 'Confirm your organization',
    box3: 'In order to proceed with the activation of your account, please check that your',
    box4: ' organization is correct',
    box5: 'Yes, it`s correct!',
    box6: 'This is not your organization? Please ',
    box7: 'contact us',
    box8: 'Create your profile',
    box9: 'Now create your profile and your access data',
    box10: 'Create profile',
    box11: 'Welcome to ',
    box12: 'Now you have access to the most complete platform in Brazil',
    box13: 'To start using Footlink, log in to the platform with the access data',
    box14: 'Enter your access data',
    box15: 'Want to learn more about Footlink? Watch the video below:',
    box16: 'Already have an account?',
    box17: 'Log in',
    box18: 'Footlink',
    box19: 'for managing your sports project',
    box20: 'you have now registered.',
    errorUser: 'All fields are mandatory.',
    errorPassword: 'Please check your password.',
    tokenError: 'Invalid token or user is already active.',
  },

  modalErroPermissionProjeto: {
    text1: 'You do not have permission to view this project.',
    text2: 'Contact project collaborators to request access.',
  },

  foot: {
    right: 'Right',
    left: 'Left',
    ambidextrous: 'Ambidextrous',
    indifferent: 'Indifferent',
  },

  weekDays: {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
  },

  gameFields: {
    competition: 'Competition',
    level: 'Level of competition',
    gameLevel: 'Game level',
    location: 'Game Location',
    observed: 'Observed game',
    date: 'Game Date',
    minutes: 'Minutes played',
    startingPlayer: 'Started as starter',
    category: 'Category',  
    position: 'Position in the game',
    stature: 'Height',
    foot: 'Preferred foot',
    gols: 'Goals',
    assistances: 'Assists',
    yellowCards: 'Yellow cards',
    redCards: 'Red cards',
    performance: 'Performance'
  },

  statureOptions: {
    alto: 'Tall',
    medio: 'Medium',
    baixo:   'Short',
  },

  categoryOptions: {
    profissional: 'Professional',
    amador: 'Amateur',
    sub23: 'Sub-23',
    sub22: 'Sub-22',
    sub21: 'Sub-21',
    sub20: 'Sub-20',
    sub19: 'Sub-19',
    sub18: 'Sub-18',
    sub17: 'Sub-17',
    sub16: 'Sub-16',
    sub15: 'Sub-15',
    sub14: 'Sub-14',
    sub13: 'Sub-13',
    sub12: 'Sub-12',
    sub11: 'Sub-11',
    sub10: 'Sub-10',
    sub9: 'Sub-9',
  },
  evaluationData: 'Evaluation Data',

  charts: {
    byPosition: 'Targeted by Position',
    byModel: 'By Model',
    forContract: 'Targeted for Hiring',
    byYear: 'By Birth Year',    
    athletes: 'Evaluated Athletes',
    byAnalist: 'By Analyst',
    filterByPosition: 'By Position',
    filterByPeriod: 'By Period',
    filterByTargeting: 'By Targeting',
    moreFilters: 'More Filters',
    noDirection: 'No Targeting',
    noData: 'We encountered an issue loading the charts, please try again later.',
    byMomentClub: 'By Club at the Time of Evaluation',
    byMomentClubShort: 'By Club at the Time',
    byActualClub: 'By Current Club',
    evaluations: 'Evaluations',
  },

  avaliationModel: {
    'GOLEIROS': 'Goalkeepers',
    'LATERAIS': 'Sweepers',
    'ZAGUEIROS': 'Defenders',
    'VOLANTES': 'Midfielders',
    'MEIAS-CENTRAIS': 'Central Midfielders',
    'EXTREMOS': 'Wingbacks',
    'ATACANTES-REFERENCIA': 'Strikers',
    'GERAL': 'General Base',
    'RELATORIO-DE-JOGO': 'Game Report',
  },
  dashboard: {
    clubs: 'Clubs',
    numer_of_clubs: 'Number of Clubs',
    agents: 'Agents',
    numer_of_agents: 'Number of Agents',
    total_count_per_project: 'Per Project',
    total_count_per_license: 'Per License',
    total_count_division: 'Per Division',
    total_count_agency: 'Per Category',    
    total_count_per_athlete: 'Per Athlete',
    total_count_agency_global: 'Global',
    total_count_agency_latam: 'Latam',
    total_count_agency_brazil: 'Brazil',
    total_count_division_1: 'Division 1',
    total_count_division_2: 'Division 2',
    total_count_division_3: 'Division 3',
    total_count_division_4: 'Division 4',
    total_count_division_null: 'No Division',
    total_count_foreign: 'Foreigners',
    transfer_market: 'Transfer Market',
    number_of_ads: 'Number of Ads',
    transfer_market_filter: 'Include inactive ads',
    total_announcement_by_type: 'By Type',    
    by_advertiser_count: 'By Advertiser',
    by_negotiation_count: 'By Negotiations',
    total_announcement_athletes_team: 'Player from Club',
    total_announcement_desired_athletes: 'Desired Player',
    total_announcement_athletes_agency: 'Player from Agency',
    market_analysis: 'Market Analysis',
    number_of_monitored_players: 'Number of Monitored Players',
    total_count_athletes_monitored_per_org: 'By Monitoring',
    total_count_evaluations_per_org: 'By Evaluations',
    total_count_reports_per_org: 'By Reports',
    chat: 'Chat',
    number_of_chats: 'Number of Chats',
    from_origin: 'By Origin',
    from_organization: 'By Organization',
    from_users: 'By User',
    total_count_agency_to_club: 'Negotiations - Agency > Club',
    total_count_club_to_club: 'Negotiations - Club > Club',
    total_count_athlete_club_to_club: 'Athlete - Club > Club',
    total_count_athlete_agency_to_club: 'Athlete - Agency > Club',
    total_count_athlete_agency_to_agency: 'Athlete - Agency > Agency',
    total_count_athlete_club_to_agency: 'Athlete - Club > Agency',
    total_count_team_club_to_agency: 'Club - Club > Agency',
    total_count_team_agency_to_club: 'Club - Agency > Club',
    total_count_team_agency_to_agency: 'Agency - Agency > Agency',
    total_count_team_club_to_club: 'Club - Club > Club',
    last_30: 'Last 30 days',
    last_60: 'Last 60 days',
    last_90: 'Last 90 days',
  },
  athlete_migration: {
    title: 'Migrate Athlete Data',
    step1Text: 'You can now migrate the data of the athletes inserted by your organization to Footlink Athletes, i.e. athletes that are registered on the platform with their official registration data. Just enter the URL of the athlete\'s Footlink profile in the field below, check if it is the correct athlete and confirm the migration of the data entered by you.',
    step2TextBold: 'Confirm the migration of the data entered by your organization for this athlete?',
    step2Text: 'This action cannot be reverted afterwards and the original athlete will be deleted.',
    original: 'Original Athlete',
    footlink: 'Footlink Athlete',
    insertUrl: 'Enter the URL of the athlete\'s Footlink profile',    
    success: 'Data migrated successfully for this athlete.',
    warning: 'This athlete is only visible to your organization.',
    warningButton: 'Migrate data to Footlink Athlete',
    message1: 'Make sure it is the correct athlete:',
    message2: 'Invalid URL or does not match the expected route.',
    message3: 'The inserted athlete is the same athlete that is being displayed.',
    message4: 'It is not possible to migrate to another customized athlete.'
  }
};
